import request from '@/utils/request'

export function getPostgraduateById(data) {
  return request({
    url: `/api/Postgraduates/GetPostgraduateById/${data}`,
    method: 'get',
  })
}

export function getPostgraduates() {
  return request({
    url: '/api/Postgraduates/GetPostgraduates',
    method: 'get',
  })
}

export function addPostgraduate(data) {
  return request({
    url: '/api/Postgraduates/AddPostgraduate',
    method: 'post',
    data,
  })
}

export function updatePostgraduate(data) {
  return request({
    url: '/api/Postgraduates/UpdatePostgraduate',
    method: 'put',
    data,
  })
}
export function deletePostgraduate(data) {
  return request({
    url: `/api/Postgraduates/DeletePostgraduate?postgraduateId=${data}`,
    method: 'delete',
  })
}
